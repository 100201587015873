<template>
  <div>
    <v-virtual-data-table
      id="matrix-by-cost-center-report-table"
      key="matrix-by-cost-center-report-table"
      ref="virtualTable"
      hide-default-header
      hide-default-footer
      fixed-header
      :dense="denseTables"
      :headers="filtered.costCenters"
      :rows="filtered.employees"
      :height="defaultTableHeight"
    >
      <template #headerRow="{ headers: costCentersForMatrixView }">
        <th class="employee_info-col no-padding sticky sticky_top sticky_left vt-offset-x">
          <table>
            <tr>
              <td class="column text-start employee_name-col">
                Employee
              </td>
              <td class="column text-end grey lighten-4 employee_total-col">
                <span>Total</span>
              </td>
              <td class="column text-end grey lighten-4 employee_total-col">
                <span>Total OVR</span>
              </td>
              <td class="column text-end grey lighten-4 employee_total-col">
                <span>Grand Total</span>
              </td>

              <td
                v-for="(client, index) in projectsWithCustomClient"
                :key="index"
                class="column text-end grey lighten-4 employee_total-col"
              >
                <span>{{ client }}</span>
              </td>
            </tr>
          </table>
        </th>
        <th
          v-for="(costCenter, index) in costCentersForMatrixView
            .filter(sc => !projectsWithCustomClient.includes(sc.title))"
          :key="index"
          class="column text-start costCenter-column"
        >
          <v-tooltip top>
            <template #activator="{ on }">
              <span v-on="on">{{ index % 2 ? 'OVR ' + costCenter.title : costCenter.title }}</span>
            </template>
            <span>{{ index % 2 ? 'OVR ' + costCenter.title : costCenter.title }}</span>
          </v-tooltip>
        </th>
      </template>

      <template #row="{ row: employee, headers: costCentersForMatrixView }">
        <th class="employee_info-col no-padding sticky sticky_left">
          <table>
            <tr>
              <td class="column text-start employee_name-col">
                <v-tooltip top>
                  <template #activator="{ on }">
                    <span v-on="on">{{ employee.name }}</span>
                  </template>
                  <span>{{ employee.name }}</span>
                </v-tooltip>
              </td>
              <td class="column text-end grey lighten-4 employee_total-col">
                <span>{{
                  getTimeFromHours({
                    hours: totalHoursByEmployees[employee.id],
                    defaultValue: '0h',
                    minusTime: employee.projects_hours && employee.projects_hours['VANP']
                      ? employee.projects_hours['VANP'].time
                      : 0
                  })
                }}</span>
              </td>
              <td class="column text-end grey lighten-4 employee_total-col">
                <span>{{ getTimeFromHours({
                  hours: totalHoursByEmployees[employee.id],
                  defaultValue: '0h',
                  isOvertime: true
                }) }}</span>
              </td>
              <td class="column text-end grey lighten-4 employee_total-col">
                <span>{{
                  getTimeFromHoursGrandTotal({
                    hours: totalHoursByEmployees[employee.id],
                    defaultValue: '0h',
                    employeeId: employee.id,
                    minusTime: employee.projects_hours && employee.projects_hours['VANP']
                      ? employee.projects_hours['VANP'].time
                      : 0
                  })
                }}</span>
              </td>
              <td
                v-for="(costCenter, index) in projectsWithCustomClient"
                :key="index"
                class="column text-end grey lighten-4 employee_total-col"
              >
                <span>{{ getTimeFromHours({
                  hours: employee.projects_hours[costCenter],
                  defaultValue: '0h',
                  isOvertime: false
                }) }}</span>
              </td>
            </tr>
          </table>
        </th>
        <td
          v-for="(costCenter, index) in costCentersForMatrixView
            .filter(sc => !projectsWithCustomClient.includes(sc.title))"
          :key="index"
          class="costCenter-column column text-start"
          :class="{ 'grey lighten-2': getTimeFromHours({
            hours: employee.projects_hours[costCenter.id],
            isOvertime: false
          }) }"
        >
          {{ index % 2 ? getTimeFromHours({
            hours: employee.projects_hours[costCenter.id],
            defaultValue: '',
            isOvertime: true
          }) : getTimeFromHours({
            hours: employee.projects_hours[costCenter.id]
          }) }}
        </td>
      </template>

      <template #body.append="{ headers: costCentersForMatrixView }">
        <tr class="total-row grey vt-offset-y">
          <th class="employee_info-col no-padding sticky sticky_left sticky_bottom">
            <table>
              <tr>
                <td class="column text-start employee_name-col">
                  <span>Total:</span>
                </td>
                <td class="column text-end employee_total-col">
                  <span>{{
                    getTimeFromHours({
                      hours: commonTotalHours,
                      minusTime: totalHoursbyCustomClient && totalHoursbyCustomClient['VANP']
                        ? totalHoursbyCustomClient['VANP'].time
                        : 0
                    })
                  }}</span>
                </td>
                <td class="column text-end employee_total-col">
                  <span>{{ getTimeFromHours({
                    hours: commonTotalHours,
                    defaultValue: '0h',
                    isOvertime: true
                  }) }}</span>
                </td>
                <td class="column text-end employee_total-col">
                  <span>{{
                    getTimeFromHoursGrandTotal({
                      hours: commonTotalHours,
                      defaultValue: '0h',
                      minusTime: totalHoursbyCustomClient && totalHoursbyCustomClient['VANP']
                        ? totalHoursbyCustomClient['VANP'].time
                        : 0
                    })
                  }}</span>
                </td>
                <td
                  v-for="(costCenter, index) in projectsWithCustomClient"
                  :key="index"
                  class="column text-end employee_total-col"
                >
                  <span>{{ getTimeFromHours({
                    hours: totalHoursbyCustomClient[costCenter],
                    defaultValue: '0h'
                  }) }}</span>
                </td>
              </tr>
            </table>
          </th>
          <th
            v-for="(costCenter, index) in costCentersForMatrixView
              .filter(sc => !projectsWithCustomClient.includes(sc.title))"
            :key="index"
            class="costCenter-column sticky sticky_bottom bottom-total"
          >
            {{ index % 2 ? getTimeFromHours({
              hours: totalHoursbyCostCenters[costCenter.id],
              defaultValue: '0h',
              isOvertime: true
            }) : getTimeFromHours({
              hours: totalHoursbyCostCenters[costCenter.id],
              defaultValue: '0h'
            }) }}
          </th>
        </tr>
      </template>
    </v-virtual-data-table>

    <v-divider />
    <v-row class="table-footer">
      <div class="footer-text">Summary: {{ totalEmployees }} employees; {{ totalCostCenters / 2 }} cost centers</div>
    </v-row>
  </div>
</template>

<script>
import has from 'lodash/has';
import { mapGetters } from 'vuex';

import VVirtualDataTable from '@/components/shared/VVirtualDataTable.vue';
import projectsWithCustomClient from '@/constants/projectsWithCustomClient';
import { defaultTableHeight } from '@/constants/tableHeights';

export default {
  components: {
    VVirtualDataTable,
  },
  data() {
    return {
      defaultTableHeight,
      commonTotalHours: undefined,
      commonTotalHoursCustomClient: undefined,
      totalHoursbyCostCenters: {},
      totalHoursbyCustomClient: {},
      totalHoursByEmployees: {},
      projectsWithCustomClient,
    };
  },
  computed: {
    ...mapGetters('matrixByCostCenterReport/filters', [
      'employeeFilter',
      'employeeStatusFilter',
      'employeeStatusFilterMap',
      'employeeOfficeFilter',
      'costCentersFilter',
      'isOVRMultiplyerApplied',
      'isEmptyHidden',
    ]),
    ...mapGetters('matrixByCostCenterReport/report', ['matrixReport']),
    ...mapGetters('costCenters/main', ['costCenters', 'costCentersForMatrixView']),
    ...mapGetters('user/settings', [
      'denseTables',
    ]),

    filledCostCentersMap() {
      /* eslint-disable no-param-reassign */
      return this.matrixEmployees.reduce((map, empl) => {
        const costCenters = Object.keys(empl.costCenters_hours);

        costCenters.forEach((el) => (map[el] = true));

        return map;
      }, {});
      /* eslint-enable */
    },

    employeeOfficeFilterMap() {
      if (!this.employeeOfficeFilter.length) return {};

      /* eslint-disable no-param-reassign */
      const officeFilterMap = this.employeeOfficeFilter.reduce((officesMap, officeId) => {
        officesMap[officeId] = officeId;

        return officesMap;
      }, {});
      /* eslint-enable */

      return officeFilterMap;
    },

    matrixEmployees() {
      if (!this.matrixReport.employees) return [];

      return this.matrixReport.employees
        .map((employee) => {
          for (const costCenterId in employee.projects_hours) {
            if (costCenterId === '*' || !has(employee.projects_hours, costCenterId)) continue;

            this.setVisualisedHours(employee.projects_hours[costCenterId]);
          }

          return employee;
        })
        .sort(({ name: leftEmployeeName }, { name: rightEmployeeName }) => {
          if (leftEmployeeName === rightEmployeeName) return 0;

          return leftEmployeeName > rightEmployeeName ? 1 : -1;
        });
    },
    filteredEmployees() {
      const employeeNameFilter = this.employeeFilter.trim();

      if (
        !employeeNameFilter
        && !this.employeeStatusFilter.length
        && !this.employeeOfficeFilter.length
        && !this.isEmptyHidden
      ) return this.matrixEmployees;

      const filteredEmployees = this.matrixEmployees
        .filter((matrixEmployee) => this.isEmployeeMatchingNameFilter(matrixEmployee)
        && this.isEmployeeMatchingStatusFilter(matrixEmployee)
        && this.isEmployeeMatchingOfficeFilter(matrixEmployee));

      return filteredEmployees;
    },
    filteredCostCenters() {
      const costCenterTitleFilter = this.costCentersFilter.trim();

      if (!costCenterTitleFilter && !this.isEmptyHidden) return this.costCentersForMatrixView;

      const filteredCostCenters = this.costCentersForMatrixView
        .filter((costCenter) => this.isCostCenterMatchingNameFilter(costCenter));

      return filteredCostCenters.concat(this.projectsWithCustomClient.map((client) => ({ id: client, title: client })));
    },
    filtered() {
      if (!this.isEmptyHidden) return { employees: this.filteredEmployees, costCenters: this.filteredCostCenters };

      const employees = this.filteredEmployees.filter((employee) => this.isEmployeeMatchingHideFilter(employee));
      const costCenters = this.filteredCostCenters
        .filter((costCenter) => this.isCostCenterMatchingHideFilter(costCenter));

      return { employees, costCenters };
    },
    totalEmployees() {
      return this.filtered.employees.length || '...';
    },
    totalCostCenters() {
      return this.filtered.costCenters.length || '...';
    },
  },

  created() {
    this.$watch(
      () => [this.filteredCostCenters, this.filteredEmployees],
      () => this.calculateAndVisualiseHours(),
    );
  },

  methods: {
    defaultHoursModel: () => ({ time: 0, overtime: 0 }),
    setVisualisedHours(hours) {
      hours.timeVisual = this.convertTimeToVisualFormat(hours.time);
      hours.overtimedVisual = this.convertTimeToVisualFormat(hours.overtime);
    },
    sumHours(hoursLeft, hoursRight) {
      hoursLeft.time += hoursRight.time;
      hoursLeft.overtime += hoursRight.overtime;

      return hoursLeft;
    },
    isEmployeeMatchingNameFilter(employee) {
      const employeeNameFilter = this.employeeFilter.trim();

      return (!employeeNameFilter || employee.name.toLowerCase().indexOf(employeeNameFilter.toLowerCase()) !== -1);
    },
    isEmployeeMatchingStatusFilter(employee) {
      return (!this.employeeStatusFilter.length || has(this.employeeStatusFilterMap, employee.status));
    },
    isEmployeeMatchingOfficeFilter(employee) {
      return (!this.employeeOfficeFilter.length || has(this.employeeOfficeFilterMap, employee.office_id));
    },
    isEmployeeMatchingHideFilter(employee) {
      if (!this.totalHoursByEmployees[employee.id]) return false;

      const { time, overtime } = this.totalHoursByEmployees[employee.id];

      return (time + Math.abs(overtime)) > 0;
    },
    isCostCenterMatchingNameFilter(costCenter) {
      const costCenterTitleFilter = this.costCentersFilter.trim();

      return (!costCenterTitleFilter || costCenter.title.toLowerCase()
        .indexOf(costCenterTitleFilter.toLowerCase()) !== -1);
    },
    isCostCenterMatchingHideFilter(costCenter) {
      if (!this.totalHoursbyCostCenters[costCenter.id]) return false;

      const { time, overtime } = this.totalHoursbyCostCenters[costCenter.id];

      return (time + Math.abs(overtime)) > 0;
    },

    convertTimeToVisualFormat(minutes) {
      if (isNaN(minutes) || minutes === 0) return '0h';

      return `${(minutes / 60).toFixed(2)}h`;
    },

    getTimeFromHours({
      hours,
      defaultValue = hours,
      isOvertime,
      minusTime = 0,
    }) {
      if (typeof hours !== 'object' || hours === null) return defaultValue;

      return isOvertime ? hours.overtimedVisual : this.convertTimeToVisualFormat(hours.time - minusTime);
    },

    getTimeFromHoursGrandTotal({
      hours,
      defaultValue = hours,
      employeeId,
      minusTime = 0,
    }) {
      if (typeof hours !== 'object' || hours === null) {
        return defaultValue;
      }

      if (employeeId && !this.totalHoursByEmployeesCustomClient[employeeId]) {
        return this.convertTimeToVisualFormat(hours.overtime + hours.time - minusTime);
      }

      return this.convertTimeToVisualFormat(
        hours.overtime + hours.time - minusTime - (
          employeeId
            ? this.totalHoursByEmployeesCustomClient[employeeId].time
            : this.commonTotalHoursCustomClient.time
        )
      );
    },

    calculateAndVisualiseHours() {
      const {
        defaultHoursModel,
        setVisualisedHours,
        sumHours,
      } = this;

      const commonTotalHours = defaultHoursModel();
      const commonTotalHoursCustomClient = defaultHoursModel();
      const byCostCenters = {};
      const byCustomClient = {};
      const byEmployees = {};
      const byEmployeesCustomClient = {};

      /* eslint-disable no-param-reassign */
      const filteredCostCentersMap = this.filteredCostCenters.reduce((costCentersMap, costCenter) => {
        costCentersMap[costCenter.id] = costCenter;

        return costCentersMap;
      }, {});
      /* eslint-enable */

      this.filteredEmployees.forEach((employee) => {
        const employeeTotalHours = defaultHoursModel();

        for (const costCenterId in employee.projects_hours) {
          if (!has(filteredCostCentersMap, costCenterId)) continue;

          const costCenterHours = employee.projects_hours[costCenterId];
          let customClientHours = defaultHoursModel();

          if (projectsWithCustomClient.includes(costCenterId)) {
            customClientHours = employee.projects_hours[costCenterId];
            byEmployeesCustomClient[employee.id] = customClientHours;
            sumHours(commonTotalHoursCustomClient, customClientHours);
            byCustomClient[costCenterId] = byCustomClient[costCenterId] || defaultHoursModel();
            sumHours(byCustomClient[costCenterId], costCenterHours);
          }

          byCostCenters[costCenterId] = byCostCenters[costCenterId] || defaultHoursModel();

          sumHours(byCostCenters[costCenterId], costCenterHours);
          sumHours(employeeTotalHours, costCenterHours);
        }

        setVisualisedHours(employeeTotalHours);
        byEmployees[employee.id] = employeeTotalHours;

        sumHours(commonTotalHours, employeeTotalHours);
      });

      setVisualisedHours(commonTotalHours);
      setVisualisedHours(commonTotalHoursCustomClient);

      for (const costCenterId in byCostCenters) {
        if (!has(byCostCenters, costCenterId)) continue;

        setVisualisedHours(byCostCenters[costCenterId]);
      }

      for (const costCenterId in byCustomClient) {
        if (!has(byCustomClient, costCenterId)) continue;

        setVisualisedHours(byCustomClient[costCenterId]);
      }

      this.commonTotalHours = commonTotalHours;
      this.commonTotalHoursCustomClient = commonTotalHoursCustomClient;
      this.totalHoursbyCostCenters = byCostCenters;
      this.totalHoursByEmployees = byEmployees;
      this.totalHoursbyCustomClient = byCustomClient;
      this.totalHoursByEmployeesCustomClient = byEmployeesCustomClient;
    },
  },
};
</script>

<style lang="less">
@import "~variables";

#matrix-by-cost-center-report-table {
  & > .v-data-table__wrapper > table {
    position: relative;
  }

  th, td {
    &.costCenter-column {
      min-width: 100px;
      max-width: 100px;
      text-align: center !important;
    }
  }

  tbody > tr:hover {
    background: @black-5pct;
  }

  tbody td.costCenter-column {
    padding: 0;

    &:hover {
      background: @black-5pct;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        display: block;
        background: @black-5pct;
        width: 100px;
        pointer-events: none;
      }
    }
  }

  thead th {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  thead .employee_info-col > table .employee_name-col,
  .employee_info-col > table .employee_total-col {
    border: solid rgba(0, 0, 0, 0.12) !important;
  }

  .employee_info-col > table {
    table-layout: fixed;
    width: 600px;
    height: 100%;
    border-spacing: 0;

    & > tr {
      background: transparent;
    }

    .employee_name-col {
      background: white;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      padding: 0 16px;
    }

    .employee_total-col {
      width: 96px;
      padding: 0 8px;
      border-width: 0 1px !important;
    }
  }

  thead .employee_info-col > table {
    td {
      font-size: inherit;
    }

    .employee_name-col {
      border-width: 0 0 1px !important;
    }

    .employee_total-col {
      border-width: 0 1px 1px 1px !important;
    }
  }

  tbody .employee_info-col > table {
    td {
      font-weight: normal;
      font-size: 1rem;
    }
  }

  tr.total-row {
    .employee_name-col {
      background-color: transparent;
    }

    .employee_total-col {
      border-bottom-width: 0 !important;
    }

    th {
      background-color: inherit;
      border-top: 1px solid rgba(0, 0, 0, 0.12) !important;
    }
  }

  &.v-data-table--dense {
    th, td {
      vertical-align: middle;
    }

    thead {
      th, td {
        height: @rowHeight;
        line-height: @rowHeight;
      }
    }
  }

  .no-padding {
    padding: 0;
  }

  .cell-content {
    display: inline-block;
    width: 100%;
    position: relative;
    white-space: nowrap;
    overflow: hidden;
  }
  .bottom-total {
    padding: 0;
  }
}
</style>
