<template>
  <v-col class="matrix-page-filters inputs-column">
    <v-text-field
      class="secondary-background"
      label="Employee"
      hide-details
      outlined
      dense
      :value="employeeFilter"
      @input="setEmployeeFilter"
    />
    <v-text-field
      class="secondary-background"
      label="Cost center"
      hide-details
      outlined
      dense
      :value="costCentersFilter"
      @input="setCostCentersFilter"
    />
    <MultiChoiceFilter
      class="secondary-background"
      label="Employee status"
      item-text="title"
      hide-details
      outlined
      dense
      :items="employeeStatusFilterOptions"
      :value="employeeStatusFilter"
      @update="setEmployeeStatusFilter"
    />
    <MultiChoiceFilter
      class="secondary-background"
      label="Office"
      item-text="name"
      hide-details
      outlined
      dense
      :items="userOffices"
      :value="userOffices.length === 1 ? [userOffices[0].id] : employeeOfficeFilter"
      :disabled="userOffices.length === 1"
      @update="setEmployeeOfficeFilter"
    />
    <DateRange
      :value="dateRangeFilter"
      :display-format="dateRange"
      @input="customTimeRangeChange"
      @timeRangeMove="timeRangeMove"
    />
    <v-switch
      label="Show billable only"
      color="primary"
      hide-details
      :input-value="isBillableOnlyShown"
      @change="changeIsBillableOnlyShown"
    />
    <v-switch
      label="Hide empty"
      color="primary"
      hide-details
      :input-value="isEmptyHidden"
      @change="changeIsEmptyHidden"
    />
  </v-col>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { mapCacheActions } from 'vuex-cache';

import { DateRange, MultiChoiceFilter } from '@/components/shared';
import { systemRolesNames } from '@/constants/roles';
import { DateHelper, DateFormat } from '@/helpers/date.helper';

export default {
  components: {
    DateRange,
    MultiChoiceFilter,
  },
  data: () => ({
    sm: 'sm2',
  }),
  computed: {
    ...mapGetters('matrixByCostCenterReport/filters', [
      'employeeFilter',
      'employeeStatusFilter',
      'employeeStatusFilterOptions',
      'employeeOfficeFilter',
      'costCentersFilter',
      'dateRangeFilter',
      'isBillableOnlyShown',
      'isEmptyHidden',
    ]),
    ...mapGetters('shared/roles', ['systemRoles']),
    ...mapGetters('shared/offices', ['offices']),
    ...mapGetters('auth/account', ['user']),

    dateRange() {
      const start = DateHelper.format(this.dateRangeFilter.startDate, DateFormat.DATE_WITH_SHORT_MONTH);
      const end = DateHelper.format(this.dateRangeFilter.endDate, DateFormat.DATE_WITH_SHORT_MONTH);

      return start === end ? `${start}` : `${start} - ${end}`;
    },

    userOffices() {
      const adminRole = this.systemRoles.find((role) => role.title === systemRolesNames.admin);

      if (!adminRole || this.user.role === adminRole.title) {
        return this.offices;
      }

      const userOffice = this.offices.find((office) => office.id === this.user.office);

      return userOffice ? [userOffice] : [];
    },
  },

  created() {
    this.getOffices();
    this.getSystemRoles();
  },

  methods: {
    ...mapActions('matrixByCostCenterReport/filters', [
      'moveDateRange',
      'changeCustomDateRange',
      'setEmployeeFilter',
      'setEmployeeStatusFilter',
      'setEmployeeOfficeFilter',
      'setCostCentersFilter',
      'setIsBillableOnlyShown',
      'setIsEmptyHidden',
    ]),
    ...mapCacheActions('shared/roles', ['getSystemRoles']),
    ...mapCacheActions('shared/offices', ['getOffices']),

    async timeRangeMove(isForward) {
      await this.moveDateRange({
        isForward,
        dateRange: this.dateRangeFilter,
      });
      this.$emit('getFilteredMatrixReport');
    },
    async customTimeRangeChange(newDate) {
      await this.changeCustomDateRange(newDate);
      this.$emit('getFilteredMatrixReport');
    },
    async changeIsBillableOnlyShown() {
      await this.setIsBillableOnlyShown(!this.isBillableOnlyShown);
      this.$emit('getFilteredMatrixReport');
    },
    changeIsEmptyHidden() {
      this.setIsEmptyHidden(!this.isEmptyHidden);
    },
  },
};
</script>
