<template>
  <Dialog
    title="Export to Excel"
    confirmation-button-text="Export"
    close-button-text="Cancel"
    :dialog="opened"
    @confirmationAction="handleConfirmation"
  >
    <template slot="content">
      <v-form
        ref="form"
        class="dialog-form"
      >
        <v-row>
          <v-checkbox
            v-model="includeLogo"
            :label="`Print the Raft logo?`"
          />
        </v-row>
      </v-form>
    </template>
  </Dialog>
</template>

<script>
import { Dialog } from '@/components/shared';

export default {
  name: 'ExportToExcelDialog',
  components: {
    Dialog,
  },
  props: {
    opened: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      includeLogo: true,
    };
  },

  methods: {
    /** @param {boolean} confirmed */
    handleConfirmation(confirmed) {
      if (!confirmed) {
        this.$emit('close');
        this.includeLogo = true;

        return;
      }

      this.$emit('addRaftLogo', this.includeLogo);
      this.$emit('close');
      this.includeLogo = true;
    },
  },
};
</script>

<style lang="less">
.dialog-form .row:not(:last-child) {
margin-bottom: 16px !important;
}
</style>
