<template>
  <v-data-table
    ref="vtContainer"
    class="virtual-data-table"
    :hide-default-header="hideDefaultHeader"
    :hide-default-footer="hideDefaultFooter"
    :fixed-header="fixedHeader"
    :dense="dense"
    :height="wrapperDims.height"
    :style="height && { height }"
  >
    <template #header>
      <thead
        ref="thead"
        class="v-data-table-header"
      >
        <tr class="vt-offset-y">
          <slot
            name="headerRow"
            :headers="clusterColumns"
          />
        </tr>
      </thead>
    </template>
    <template #body>
      <tbody
        ref="tbody"
        tabindex="0"
      >
        <tr
          v-for="(row, index) in clusterRows"
          :key="index"
        >
          <slot
            name="row"
            :row="row"
            :headers="clusterColumns"
          />
        </tr>
        <slot
          name="body.append"
          :headers="clusterColumns"
        />
      </tbody>
    </template>
  </v-data-table>
</template>

<script>
import debounce from 'lodash/debounce';

const getMiddleElement = (list) => list[Math.floor(list.length / 2)];

export default {
  name: 'VVirtualDataTable',

  props: {
    hideDefaultHeader: {
      type: Boolean,
      default: false,
    },
    hideDefaultFooter: {
      type: Boolean,
      default: false,
    },
    fixedHeader: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    height: {
      type: String,
      default: undefined,
    },

    headers: {
      type: Array,
      required: true,
      default() {
        return [];
      },
    },
    rows: {
      type: Array,
      required: true,
      default() {
        return [];
      },
    },
  },

  data() {
    return {
      scrollEl: null,
      wrapperEl: null,
      theadEl: null,
      tbodyEl: null,

      scrollTop: 0,
      scrollLeft: 0,
      currentXCluster: 0,
      currentYCluster: 0,

      additionalOffsets: { x: 0, y: 0 },

      cellDims: { width: 0, heigth: 0 },

      clusterCapacity: 2,
      blockCapacity: {
        rows: 30,
        columns: 25,
      },
    };
  },

  computed: {
    columnsInCluster() {
      return this.columnsInBlock * this.blocksInXCluster;
    },

    blockDims() {
      return {
        width: this.blockCapacity.columns * this.cellDims.width,
        height: this.blockCapacity.rows * this.cellDims.height,
      };
    },

    clusterDims() {
      return {
        width: this.clusterCapacity * this.blockDims.width,
        height: this.clusterCapacity * this.blockDims.height,
      };
    },

    wrapperDims() {
      const {
        cellDims,
        additionalOffsets,
        headers,
        rows,
      } = this;

      return {
        width: additionalOffsets.x + headers.length * cellDims.width,
        height: additionalOffsets.y + rows.length * cellDims.height,
      };
    },

    clusterYEdges() {
      const {
        blockCapacity,
        clusterCapacity,
      } = this;

      const leftEdge = Math.max((blockCapacity.rows * (clusterCapacity - 1)) * this.currentYCluster, 0);
      const rightEdge = leftEdge + (blockCapacity.rows * clusterCapacity);

      return [leftEdge, rightEdge];
    },

    clusterRows() {
      return this.rows.slice(...this.clusterYEdges);
    },

    clusterXEdges() {
      const {
        blockCapacity,
        clusterCapacity,
      } = this;

      const leftEdge = Math.max((blockCapacity.columns * (clusterCapacity - 1)) * this.currentXCluster, 0);
      const rightEdge = leftEdge + (blockCapacity.columns * clusterCapacity);

      return [leftEdge, rightEdge];
    },

    clusterColumns() {
      return this.headers.slice(...this.clusterXEdges);
    },

    offsets() {
      const { cellDims } = this;

      const [leftRowsEdge] = this.clusterYEdges;
      const [leftColumnsEdge] = this.clusterXEdges;

      return {
        top: Math.max(leftRowsEdge * cellDims.height, 0),
        left: Math.max(leftColumnsEdge * cellDims.width, 0),
      };
    },
  },

  watch: {
    wrapperDims() {
      this.wrapperEl.style.width = `${this.wrapperDims.width}px`;
    },

    offsets() {
      this.wrapperEl.style.paddingTop = `${this.offsets.top}px`;
      this.wrapperEl.style.paddingLeft = `${this.offsets.left}px`;
    },
  },

  mounted() {
    this.$nextTick(function () {
      const vtContainerElement = this.$refs.vtContainer.$el;

      const scrollEl = vtContainerElement;
      const wrapperEl = scrollEl.querySelector('.v-data-table__wrapper');
      const theadEl = this.$refs.thead;
      const tbodyEl = this.$refs.tbody;

      this.scrollEl = scrollEl;
      this.wrapperEl = wrapperEl;
      this.theadEl = theadEl;
      this.tbodyEl = tbodyEl;

      this.resetScrolling();
      this.scrollTop = this.getScrollTop();
      this.scrollLeft = this.getScrollLeft();

      this.setupScrollHandling();
      this.setupWindowResizeHandling();

      if (this.headers.length || this.rows.length) this.calculateDimensions();

      this.$watch(
        () => [this.headers, this.rows],
        () => {
          this.resetScrolling();
          this.calculateDimensions();
        },
      );
    });
  },

  methods: {
    getScrollTop() {
      return this.scrollEl.scrollTop;
    },

    getScrollLeft() {
      return this.scrollEl.scrollLeft;
    },

    resetScrolling() {
      this.scrollEl.scrollTop = 0;
      this.scrollEl.scrollLeft = 0;
    },

    calculateAdditionalOffsets() {
      const offsetXClass = 'vt-offset-x';
      const offsetYClass = 'vt-offset-y';
      const offsetElements = this.wrapperEl.querySelectorAll(`.${offsetXClass}, .${offsetYClass}`);

      const additionalOffsets = { x: 0, y: 0 };

      offsetElements.forEach((offsetElement) => {
        if (offsetElement.classList.contains(offsetXClass)) {
          additionalOffsets.x += offsetElement.offsetWidth;

          return;
        }

        additionalOffsets.y += offsetElement.offsetHeight;
      });

      this.additionalOffsets = additionalOffsets;
    },

    calculateDimensions() {
      const rowsNodes = this.tbodyEl.children;
      const columnsNodes = this.theadEl.children[0].children;

      const cellDims = { width: 0, height: 0 };

      // Getting additional <table> tag dimensions
      const tbodyElementStyle = window.getComputedStyle(this.tbodyEl);
      let borderSpacing = 0;

      if (tbodyElementStyle.borderCollapse !== 'collapse') {
        borderSpacing = parseInt(tbodyElementStyle.borderSpacing, 10) || 0;
      }

      this.calculateAdditionalOffsets();

      if (rowsNodes.length) {
        const middleRow = getMiddleElement(rowsNodes);

        cellDims.height = middleRow.offsetHeight + borderSpacing;
      }

      if (columnsNodes.length) {
        const middleColumn = getMiddleElement(columnsNodes);

        cellDims.width = middleColumn.offsetWidth + borderSpacing;
      }

      const isCellDimsChanged = (
        this.cellDims.width !== cellDims.width
        || this.cellDims.height !== cellDims.height
      );

      if (isCellDimsChanged) {
        this.cellDims = cellDims;
      }

      return isCellDimsChanged;
    },

    handleScrolling() {
      this.scrollTop = this.getScrollTop();
      this.scrollLeft = this.getScrollLeft();

      this.currentXCluster = Math.floor(this.scrollLeft / (this.clusterDims.width - this.blockDims.width)) || 0;
      this.currentYCluster = Math.floor(this.scrollTop / (this.clusterDims.height - this.blockDims.height)) || 0;
    },

    setupScrollHandling() {
      this.scrollEl.addEventListener('scroll', this.handleScrolling, false);
    },

    handleWindowResizing() {
      this.calculateDimensions();
    },

    setupWindowResizeHandling() {
      this.handleWindowResizing = debounce(this.handleWindowResizing, 150);
      window.addEventListener('resize', this.handleWindowResizing, false);
    },
  },
};
</script>

<style lang="less" scoped>
.virtual-data-table {
  overflow-x: auto;
  overflow-y: auto;
}
</style>

<style lang="less">
.virtual-data-table {
  position: relative;

  .v-data-table__wrapper {
    height: auto;
    overflow: visible !important;

    & > table {
      width: auto !important;
    }
  }

  tbody {
    outline: 0;
  }
}
</style>
