<template>
  <v-row class="pa-4">
    <v-col
      v-if="isFiltersOpened"
      xl="2"
      lg="3"
    >
      <v-card class="pa-4">
        <v-card-title class="small-title">
          Filters
        </v-card-title>
        <MatrixByCostCenterReportPageFilters
          @getFilteredMatrixReport="getFilteredMatrixReport"
        />
      </v-card>
    </v-col>

    <v-col
      :xl="isFiltersOpened ? 10 : 12"
      :lg="isFiltersOpened ? 9 : 12"
    >
      <v-card>
        <v-row class="pa-4 pb-0">
          <v-col>
            <v-card-title class="small-title">
              <v-btn
                icon
                class="mr-2"
                @click="(isFiltersOpened = !isFiltersOpened)"
              >
                <v-icon>mdi-filter-variant</v-icon>
              </v-btn>
              Cost Center Matrix Report
            </v-card-title>
          </v-col>
          <v-col class="d-flex justify-end">
            <JsonToCSV
              :data="dataCsv"
              :labels="headersCsv"
              :delimiter="';'"
              :name="`VACP_LEAV_KharkivOffice-${currentDate}.csv`"
            >
              <Button
                id="matrix-report-export-to-csv-button"
                type="button"
                color="primary"
                :action-names="[loadingTypes.matrixReportExportToCsvlButton]"
                @clickAction="renderCostMatrix"
              >
                <v-icon v-if="!isLargeScreen">mdi-file</v-icon>
                <span v-else>Export To CSV</span>
              </Button>
            </JsonToCSV>
            <Button
              id="matrix-report-export-to-excel-button"
              color="primary"
              class="ml-6"
              :action-names="[loadingTypes.matrixReportExportToExcelButton]"
              @clickAction="showDialogHandler"
            >
              <v-icon v-if="!largeScreenHelper">mdi-file-table</v-icon>
              <span v-else>Export To Excel</span>
            </Button>
          </v-col>
        </v-row>

        <MatrixByCostCenterReportTable />
      </v-card>
    </v-col>
    <ExportToExcelDialog
      :opened="isExcelExportDialogOpen"
      @close="isExcelExportDialogOpen = false"
      @addRaftLogo="addRaftLogoHandler"
    />
  </v-row>
</template>

<script>
import { debounce } from 'lodash';
import moment from 'moment';
import * as JsonToCSV from 'vue-json-csv';
import { mapActions, mapGetters } from 'vuex';
import { mapCacheActions } from 'vuex-cache';

import { Button } from '@/components/shared';
import { loadingTypes } from '@/constants/loadingTypes';
import { NotificationHelper } from '@/helpers/notification.helper';
import { isLargeScreen } from '@/helpers/screen.helper';
import apiClient from '@/services/api-client';

import ExportToExcelDialog from '../shared/ExportToExcelDialog.vue';

import MatrixByCostCenterReportPageFilters from './MatrixByCostCenterReportPageFilters.vue';
import MatrixByCostCenterReportTable from './MatrixByCostCenterReportTable.vue';

export default {
  components: {
    Button,
    MatrixByCostCenterReportPageFilters,
    MatrixByCostCenterReportTable,
    JsonToCSV,
    ExportToExcelDialog,
  },
  data() {
    return {
      isFiltersOpened: true,
      loadingTypes,
      dataCsv: [],
      headersCsv: {},
      currentDate: moment().format('YYYY-MM-DD'),
      isExcelExportDialogOpen: false,
      addRaftLogoValue: true,
    };
  },
  computed: {
    ...mapGetters('matrixByCostCenterReport/filters', [
      'dateRangeFilter',
      'isBillableOnlyShown',
      'employeeFilter',
      'employeeStatusFilter',
      'employeeOfficeFilter',
      'costCentersFilter',
      'isEmptyHidden',
    ]),
    ...mapGetters('matrixByCostCenterReport/report', ['matrixReport']),
    ...mapGetters('auth/account', ['user']),
    largeScreenHelper() {
      return isLargeScreen(this.$vuetify.breakpoint, true);
    },
  },
  async created() {
    await this.getCostCenters(this.user.id);
    await this.getMatrixReport({
      startDate: this.dateRangeFilter.startDate,
      endDate: this.dateRangeFilter.endDate,
      isBillableOnlyShown: this.isBillableOnlyShown,
    });
  },
  mounted() {
    this.renderCostMatrix();
  },
  methods: {
    ...mapActions('matrixByCostCenterReport/report', ['getMatrixReport']),
    ...mapCacheActions('costCenters/main', ['getCostCenters']),
    getMatrixReportDebounced: debounce(function (args) {
      this.getMatrixReport(args);
    }, 500),
    getFilteredMatrixReport() {
      this.getMatrixReportDebounced({
        startDate: this.dateRangeFilter.startDate,
        endDate: this.dateRangeFilter.endDate,
        isBillableOnlyShown: this.isBillableOnlyShown,
      });
    },
    showDialogHandler() {
      this.isExcelExportDialogOpen = true;
    },
    /** @param {boolean} data */
    addRaftLogoHandler(data) {
      this.addRaftLogoValue = data;
      this.exportToExcel();
    },
    async exportToExcel() {
      const response = await apiClient.matrixByCostCenterReportApi
        .exportMatrixReportToExcel({
          startDate: this.dateRangeFilter.startDate,
          endDate: this.dateRangeFilter.endDate,
          billableOnly: this.isBillableOnlyShown,
          employee: this.employeeFilter,
          costCenters: this.costCentersFilter,
          employeeStatus: this.employeeStatusFilter,
          officeIds: this.employeeOfficeFilter,
          hideEmpty: this.isEmptyHidden,
          includeLogo: this.addRaftLogoValue,
        }, { loadingType: loadingTypes.matrixReportExportToExcelButton });

      this.$saveFile(response);
    },
    async renderCostMatrix() {
      try {
        const { data } = await apiClient.matrixByCostCenterReportApi.getDataForGenerateCsvFile();

        this.dataCsv = data.data;
        this.headersCsv = data.headers;
      } catch (error) {
        if (error.response && error.response.data.day_limit) {
          const errorMessage = NotificationHelper
            .formatLoggedTimeErrorMessage(error.response.data, this.dateFormatSettings);

          NotificationHelper.showError(errorMessage);

          return;
        }

        throw error;
      }
    },
  },
};
</script>

<style>
</style>
